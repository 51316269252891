.modal-wide {
  .modal-dialog {
    max-width: 700px;
  }
}

.user-report-scroll{
  height: 300px;
  overflow-y: auto;
  width: 100%;
}
.user-report-scroll::-webkit-scrollbar {
  width: 5px;
}
.user-report-scroll::-webkit-scrollbar-track {
  background: gainsboro;
}
.user-report-scroll::-webkit-scrollbar-thumb {
  background: ghostwhite;
}

.wis-modal {
  .modal-content {
    border-radius: 31px;
    border: 1px solid #d8d8d8;
    box-shadow: 0px 5px 5px #00aeb347;
  }
  .modal-title {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 28px;
    line-height: 50px;
    color: #3c3d43;
    word-break: break-word;
    word-wrap: break-word;
  }

  .modal-header,
  .modal-body {
    padding: 41px 40px;
  }
  label {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #506278;
    margin-bottom: 4px;
    small {
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #bebebe;
    }
  }
  .form-group {
    margin-bottom: 30px;
  }

  .form-control {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    background-color: #ebf7f6;
    color: #242424;
    border-color: #ebf7f6;
    border-radius: 4px;
    font-size: 16px;
    line-height: 25px;
    padding-left: 35px;
    height: 58px;
  }

  .upload-file {
    position: absolute;
    right: 32px;
    top: auto;
    margin-top: 25px;
  }

  .date,
  .select {
    background-image: url("../images/admin/Polygon 65.svg");
    background-repeat: no-repeat;
    background-position: 98% center;
    appearance: none;
  }

  .access {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    background-color: #ebf7f6;
    color: #242424;
    border-color: #ebf7f6;
    border-radius: 4px;
    font-size: 16px;
    line-height: 25px;
    padding: 8px 35px 40px;
  }

  .access-level {
    padding: 20px 10px;
    border-bottom: 1px solid #ccc;
  }

  .btn-custom {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    color: #ffffff;
    background: #12aeb4;
    box-shadow: 5px 5px 10px #1f7a7e89;
    border-radius: 9px;
    width: 282px;
    height: 57px;
    margin: 20px 0px 10px 0px;

    &:active:active {
      box-shadow: none;
    }
  }
  .scroll {
    max-height: 285px;
    overflow-y: scroll;
    border-right: 1px solid #ccc;
    padding-right: 5px;
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: #12aeb4;
      border-radius: 10px;
      height: 50px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
  }
  @media (min-width: 1600px) {
    .modal-dialog {
      max-width: 696px;
    }
  }
  @media only screen and (max-width: 1440px) {
    .modal-dialog {
      max-width: 696px;
    }
    .btn-custom {
      width: 224px;
      font-size: 16px;
      line-height: 26px;
      height: 44px;
    }
    .modal-header,
    .modal-body {
      padding: 16px;
    }
    .upload-file {
      right: 6px;
    }
    label,
    .form-control {
      font-size: 14px;
      line-height: 23px;
    }
    .form-control {
      padding-left: 12px;
    }
  }
  @media only screen and (max-width: 480px) {
    .modal-title {
      font-size: 18px;
      line-height: 35px;
      padding-left: 0px;
    }
    .btn-custom {
      width: 100%;
    }
  }
}
