@use "../../utilities/variables" as var;

#socialCreateModal, .socialEditModal {
  .modal-title {
    font-family: "Nunito Sans", sans-serif;
    font-weight: 700;
    font-size: 36px;
    line-height: 49px;
    color: var.$color-1;
  }

  .modal-header .close img {
    width: 35px;
  }

  .modal-content {
    border-radius: 24px;
  }
  .title {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 28px;
    line-height: 50px;
    color: #3c3d43;
    margin-bottom: 17px;
  }
  .label-title {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #506278;
    margin-bottom: 9px;
    
    small {
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #a6a7ae;
      }
  }

  input.form-control{
    &, &:focus{
        background-color: var.$color-2;
        color: #222;
        border-color: var.$color-2;
        font-size: 16px;
        line-height: 25px;
        height: 58px;
      }
  }

  textarea.form-control{
    &, &:focus{
        background-color: var.$color-2;
        color: #222;
        border-color: var.$color-2;
        font-size: 16px;
        line-height: 25px;
        height: 143px;
    }
  }

  .upload-file {
    position: absolute;
    right: 10px;
    top: auto;
    margin-top: 25px;

    img {
        cursor: pointer;
      }
  }

  .table{
    
    thead tr th {
        font-family: "Nunito Sans", sans-serif;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        color: #7d8592;
        padding: 8px;
      }

      tbody tr td {
        font-family: "Nunito Sans", sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #0a1629;
        padding: 8px;
      }
  }

  .scroll {
    max-height: 214px;
    overflow-y: scroll;
    border-right: 1px solid #ccc;
    padding-right: 5px;
    
    &::-webkit-scrollbar {
        width: 4px;
        height: 5px;
      }
    &::-webkit-scrollbar-thumb {
        background: var.$color-1;
        border-radius: 10px;
        height: 50px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 10px;
      }
  }

  .member-img {
    width: 24px;
    height: 24px;
    border-radius: 50px;
    margin-right: 10px;
  }

  .view-more {
    font-family: "Nunito Sans", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: var.$color-1;
  }

  .topic {
    padding: 15px 10px;
    background-color: var.$color-2;

    .badge {
        font-family: "Nunito Sans", sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #0a1629;
        padding: 6px 20px;
        border: 1px solid var.$color-1;
        border-radius: 20px;
        margin-bottom: 15px;
        word-break: break-word;
        white-space: normal;

        img {
            width: 20px;
            margin-left: 10px;
          }
      }
      input.form-control {
        height: 40px;
        background-color: var.$color-2;
        border: 1px solid var.$color-1;
        border-radius: 20px;
      }
  }
  .btn-custom {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    color: var.$color-white;
    width: 330px;
    height: 57px;
    background: var.$color-1;
    box-shadow: 5px 5px 10px #1f7a7e89;
    border-radius: 9px;
    margin-top: 16px;
    margin-bottom: 32px;
  }
}
