.memberShip-list thead tr th {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #12aeb4;
  padding: 10px 5px;
  white-space: nowrap;
}

  .memberShip-list tbody tr td {
    font-size: 13px;
  }

  .dropdown #member-option i {
    color: #fff;
    background: #a0dbdd;
    border-radius: 14px;
    font-size: 18px;
    padding: 13px 19px;
  }
    
  .memberShip-list tbody tr td .dropdown-menu {
    background: #dbf3f4;
    box-shadow: 5px 5px 10px rgba(34, 136, 133, 0.3607843137);
    border-radius: 25px;
    border: none;
    width: 100px;
    padding: 0px;
  }
.memberShip-list tbody tr td .dropdown-menu .dropdown-item {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #19b0b6;
  letter-spacing: 0.38px;
  padding: 0.25rem 1.3rem;
}

.memberShip-list tbody tr td .dropdown-menu .dropdown-item:hover {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px
}

.memberShip-list tbody tr td .dropdown-menu .dropdown-item:active {
  background-color: #fff;
}

.table-responsive .table thead tr th,
.table-responsive .table tbody tr td{
  border-left: none;
  border-right: none;
}