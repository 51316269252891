@use "./utilities/_variables.scss" as var;

.nav.nav-tabs {
  border: none;
  align-items: center;

  .nav-item {
    min-width: 200px;
    margin-right: 20px;
    text-align: center;

    .nav-link {
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: #8d9296;
      text-align: center;
      padding-left: 0;
      padding-right: 0;
      width: 100%;

      &:active,
      &:hover,
      &:focus {
        color: var.$color-1;
        background-color: var.$color-white;
        border: none;
        border-bottom: 3px solid var.$color-1;
      }
    }

    .nav-link.active {
      color: var.$color-1;
      background-color: var.$color-white;
      border: none;
      border-bottom: 3px solid var.$color-1;
    }
  }
}

// Table
.table {
  font-family: "Nunito Sans", sans-serif;
  thead {
    tr {
      th {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: var.$color-1;
        padding: 10px 5px;
      }
    }
  }
  tr {
    td {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var.$color-black;
      vertical-align: middle;
      text-align: start;
      padding: 10px 5px;
    }
  }
}
