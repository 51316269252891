// Utilities
@use './utilities/_variables.scss' as var;
@use "./utilities/mixins.scss" as *;

// Base
@use "./bootstrap-extension";
@use "./base";

// Components
@use "components/components";

/* visual appearance of the custom checkbox */
#makePageLive-custom-checkbox input[type="checkbox"]:before {
  content: '';
  position: absolute;
  left: 10;
  top: 10;
  width: 2em;
  height: 2em;
  border: 1px solid #506278; 
  border-radius: 2px;
  background-color: #fff;
}

/* checkbox when checked */
#makePageLive-custom-checkbox input[type="checkbox"]:checked:before{
  background-color: var.$color-1;
}

/* inner checkmark */
#makePageLive-custom-checkbox input[type="checkbox"]:after{
  content: '\2713'; 
  position: absolute;
  top: 25%;
  left: 7%;
  color: #fff; /* Checkmark color */
  font-size: 22px;
  font-weight:bold;
}

.salespage-header{
  background-color: var.$color-1;
  border-radius: .3em;
  margin-top: 5em;

  h5{
    color: var.$color-white;
    padding: 1em 0 0 1em;
    margin: 0;
  }
}

.select-content-type{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .7em;
    margin-bottom: 1em;
  }
  .code-content{
    width: 100%;
    border: 1px solid rgb(204, 197, 197) !important;
    outline: none;
    border-radius: .5em;
    font-size: 16px !important;
    font-weight: 300 !important;
  }
  .drag-hover:hover{
    cursor: pointer;
    border: 1px solid #a7bad4;
  }

  #welcome .welcome-text {
    color: #7a869a;
    font-size: 14px;
    line-height: 21px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    margin-left: 36px;
    text-align: justify;
  }
  
#choose-file,
#choose-file1,
#choose-cover-file {
  display: none;
  visibility: hidden;
}
#wantToCreateModal .modal-body,
#addAudioModal .modal-body,
#addVideoModal .modal-body {
  padding: 0px 16px;
  /* margin: 35px 0px; */
  margin-top: 35px;
  margin-bottom: 10px;
}
.custom-button-design{
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #12aeb4;
    background: #c7ebeb;
    border-radius: 6px;
    width: 150px;
    height: 42px;
    border: none;
  }
  .custom-button-design:hover{
    background-color: #12aeb4;
    color: #fff;
  }
  .custom-button-member{
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #12aeb4;
    background: #c7ebeb;
    border-radius: 6px;
    width: 12em;
    height: 2.6em;
    border: none;

    /* Center text horizontally and vertically */
    display: flex;
    justify-content: center; /* Horizontal centering */
    align-items: center;    /* Vertical centering */
    text-align: center;     /* Ensure text aligns properly */
    padding: 0;  
  }
  .custom-button-member:hover{
    background-color: #12aeb4;
    color: #fff;
  }
  ul.nav-tabs .nav-link {
    text-decoration: none !important; /* Forces no underline */
  }

  .content-final-preview .template .edit {
    position: absolute;
    right: -0px;
    bottom: 60px;
    width: 40px;
    height: 32px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .content-final-preview .template .delete {
    position: absolute;
    right: -0px;
    bottom: 20px;
    width: 40px;
    height: 32px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .content-final-preview .template .link {
    margin-bottom: 30px;
  }

  .se-component figure{
    height: 25em !important;
    padding-bottom: .5em !important;
  }
  .css-1fdsijx-ValueContainer{
    background-color: #ebf7f6;
  }
  .country-dropdown{
    appearance: none;
    -webkit-appearance: none;
    background-image: url("../images/admin/Polygon 65.svg");
    background-repeat: no-repeat;
    background-position: 98% center;
  }
  .admin-courses .content-header .btn-custom {
    font-size: 18px;
    line-height: 26px;
    width: 230px;
    height: 40px;
  }

  #wantToCreateModal .modal-body,
  #addAudioModal .modal-body,
  #addVideoModal .modal-body {
    /* padding: 5px 40px; */
    padding-top: 5px;
    padding-bottom: 40px;
  }

  @media only screen and (max-width: 380px) {
    #stepsSetup .company-logo {
      margin: 0 auto;
    }
    .subs-card-main {
      width: 100% !important;
      height: 90%;
      display: flex;
      flex-direction: column;
      border-radius: 0.5em;
      box-shadow: rgba(18, 174, 180, 0.2392156863) 0px 2px 8px 0px;
      align-items: center;
      padding: 1em !important;
    }
    .explore-subscription-section {
      width: 100% !important;
      display:grid;
      grid-template-columns: auto auto;
      flex-direction: row;
      flex-wrap: wrap;
      position: relative;
      padding: 0 5vw;
    }
  }

  @media only screen and (max-width: 320px) {

    .subs-card-main {
      width: 100% !important;
      height: 70%;
      display: flex;
      flex-direction: column;
      border-radius: 0.5em;
      box-shadow: rgba(18, 174, 180, 0.2392156863) 0px 2px 8px 0px;
      align-items: center;
      padding: 1em !important;
    }
    .explore-subscription-section {
      width: 100% !important;
      display:grid;
      grid-template-columns: auto auto;
      flex-direction: row;
      flex-wrap: wrap;
      position: relative;
      padding: 0 1vw;
    }
  }

  .student-list-next-prev {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
    
  }
  .student-list-next-prev {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
  }
  
  .student-list-next-prev button:nth-child(1) {
    width: 7em;
    background-color: #00AEB3;
    color: white;
    padding: 0.5em 1em;
    border: none;
    border-radius: 5px;
    margin-top: 1em;
  }
  
  
  .student-list-next-prev button:nth-child(2) {
    width: 7em;
    background-color: #00AEB3;
    color: white;
    padding: 0.5em 1em;
    border: none;
    border-radius: 5px;
    margin-top: 1em;
  }
  
  .export-student button{
    width: 6em;
    height: 2.5em;
    background-color: #00AEB3;
    color: white;
    padding: 0.5em 1em;
    border: none;
    border-radius: 5px;
    margin-right: 2.5em;
    margin-bottom: -2.5em;
    margin-top: 2em;
    cursor: pointer;
  }
  
  .export-students{
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .export-students button{
    width: 6em;
    background-color: #00AEB3;
    color: white;
    padding: 0.5em 1em;
    border: none;
    border-radius: 5px;
    margin-right: 2.3em;
    margin-bottom: -9.5em;
    cursor: pointer;
  }

  .explore-subscription-section {
    width: 66.5%;
    display: grid;
    grid-template-columns: auto auto !important;
    gap: 4.5em;
    position: relative;
  }

  @media only screen and (max-width: 480px) {

    .subs-card-main {
      width: 100% !important;
      height: 90%;
      
      display: flex;
      flex-direction: column;
      border-radius: 0.5em;
      box-shadow: rgba(18, 174, 180, 0.2392156863) 0px 2px 8px 0px;
      align-items: center;
      padding: 1em !important;
    }
    .explore-subscription-section {
      width: 100% !important;
      display:grid;
      grid-template-columns: auto auto;
      flex-direction: row;
      flex-wrap: wrap;
      position: relative;
      padding: 0 50px;
    }
  }

  .slide-card__img_wrapper {
    position: relative;
  }
  .slide-card__img_wrapper__img {
    width: 100%;
    height: 15em;
    object-fit: fill;
    border-radius: 8px;
  }

  
.btn-new-primary {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    color: #ffffff;
    background: #12aeb4;
    box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
    border-radius: 9px;
    border: none;
    align-items: center;

    &:hover, &:focus, &:visited, &:active, &:focus-visible, &:not(:disabled):active {
      background-color: #12aeb4 !important;
      color: #fff !important;
    }
  }
  .payment-settings--box, .payment-settings--box:hover{
    background-color: #00AEB3;
    color: #333244;
    width: auto;
    min-width: 150px;
    margin-left: 20px;
    cursor: auto;
  }
  .payment-settings--setup-done{
    margin: 5px;
  }
  .payment-settings--setup-info {
    margin: 5px;
  }
  .payment-settings--setup-info > svg{
    color: #12aeb4;
  }
  
  .edit-payment-button{
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #707070;
    background: #fff;
    border-radius: 20px;
    border-color: #12AEB4;
    width: 100px;
    height: 34px;
  }
  .edit-payment-button:hover, .edit-payment-button:focus, .edit-payment-button:visited, .edit-payment-button:active, .edit-payment-button:focus-visible{
    color: #707070 !important;
    background: #fff !important;
    border-color: #12AEB4 !important;
  }
  
  .payment-settings--switch::before{
    left:2rem !important;
  }
  .payment-settings--switch::after{
    left:calc(2.25rem - 2px) !important;
  }
  
  .payment-settings-notes{
    margin-top: 50px;
    margin-left: 20px;
  }
  
  .payment-options{
    margin-top: 50px;
  }
  
  .payment-settings-notes--heading{
    margin-left: -15px;
    font-weight: 500;
  }
  .price-info {
    position: relative;
  }
  
  .info-icon {
    cursor: pointer;
  }
  
  .info-hover {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Show info-hover when .info-icon is hovered */
  .price-info:hover .info-hover {
    display: block;
  }
  
  .save-button-sales{
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    color: #ffffff;
    background: #12aeb4;
    box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
    border-radius: 9px;
    margin-top: 1.2em;
    margin-bottom: 1em;
    height: 2em;
    width: 12em;
    border: none;
  } 
  

  .explore-subscription-section {
    width: 66.5%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4.5em;
    position: relative;
  }

  .slide-card--highlight:hover{
    box-shadow: 8px 8px 10px rgba(60, 118, 119, 0.2784313725);
    border: 3px solid #12aeb4;
  }
  .slide-card--highlight{
    border: 3px solid transparent;
  }
  
  .navbar-nav .user-menu .dropdown-menu {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: 9px;
    padding: 0;
    width: 140px;
  }
  .navbar-nav .user-menu .dropdown-item {
    display: block;
    font-size: 14px;
    width: 100%;
    padding: 0.25rem 1rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }
  
  .navbar-nav .user-menu .dropdown-item:hover,
  .navbar-nav .user-menu .dropdown-item:focus {
    color: #fff;
    text-decoration: none;
    background-color: #89d7da;
  }

  .description-content img {
    height: 22em;
    width: 100%;
    max-width: 40em;
  }

  .audio-player-wrapper {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
  
  .audio-player-wrapper audio {
    width: 100%;
  }

  .add-coupon-btn{
    font-family: "Nunito Sans", sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    background: #12aeb4;
    box-shadow: 0px 6px 12px rgba(63, 140, 255, 0.262745098);
    border-radius: 14px;
    width: 9em;
    height: 3em;
    margin: 20px 0px 10px 0px;
    border: none;
  }

  .discount-input {
    color: #888;
    text-align: center;
    background: #EDF6F5;
    width: 210px;
    height: 30px;
    border: none;
 }
  .audio-file audio {
    width: 100%;
    max-width: 100%;
    display: block;
    margin-bottom: 10px;
  }
  
  .elipsis-alignment {
    display: inline-block;
    text-align: left;
  }

  .coupon-members-list{
    background-color: #ebf8ff;
    border-color: #ebf8ff;
    padding: 15px 15px 15px 35px;
    height: 150px;
    overflow-y: auto;
    overflow-x:hidden;
  }
  .coupon-members-list::-webkit-scrollbar {
    width: 5px;
  }
  
  .coupon-members-list::-webkit-scrollbar-thumb {
    background-color: #888; 
    border-radius: 5px;
  }
  
  .coupon-members-list::-webkit-scrollbar-thumb:hover {
    background-color: #555; 
  }

  .se-component audio {
    width: 100%;
  }

  .icon-button {
    background: none;
    border: none;
    outline: none; 
    cursor: pointer;
  }
  
  .icon-button:hover {
    color: #3ed2db;
  }
  
  .custom-scrollbar {
    scrollbar-width: thin; 
    scrollbar-color: #12aeb4 transparent; 
  }
  
  .custom-scrollbar::-webkit-scrollbar {
    width: 5px; 
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background: transparent; 
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #12aeb4; 
    border-radius: 10px; 
  }
  
  .cancel-button {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #12aeb4;
    background: #ffffff;
    border-radius: 20px;
    width: 110px;
    height: 36px;
    border: none;
    text-align: center !important;
    box-shadow: 0px 4px 8px rgba(128, 128, 128, 0.5);
  }
  
  .apply-button {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #ffffff;
    background: #12aeb4;
    border-radius: 20px;
    width: 110px;
    height: 36px;
    border: none;
    text-align: center !important;
    box-shadow: 0px 4px 8px rgba(128, 128, 128, 0.5);
  }
  
  .clear-button{
    border: 3px dotted #12aeb4;
    background-color: transparent;
    border-radius: 50px;
    padding: 1px 0;
    width: 5.5em;
    height: 1.8em;
  }
  
  .clear-button i {
    margin-right: 1em;
    color: red;
  }
  
  .clear-button span {
    color: black;
    margin-right: 1em;
  }
  
  .dropdown-toggle-no-arrow::before {
    display: none !important;
  }
  
  .dropdown-toggle-no-arrow::after {
    display: none !important;
  }

  
.custom-navbar {
  background-color: #8fd0d5;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar-brand-custom img {
  width: 80px;
  height: 50px;
}

.custom-navbar .nav-link,
.custom-navbar .dropdown-toggle,
.custom-navbar .dropdown-item,
.custom-navbar .form-control {
  color: #12aeb4;
}

.nav-item-custom .nav-link,
.right-nav .nav-link {
  font-size: 1rem;
}

.nav-item-custom .dropdown-toggle::after,
.right-nav .dropdown-toggle::after {
  margin-left: 0.5rem;
  color: #12aeb4;
}

.search-icon-nav {
  background-color: #e9ecef;
  color: #12aeb4;
  cursor: pointer;
}

.search-form .form-control::placeholder {
  color: black;
}

.login-button {
  color: #12aeb4;
  font-weight: 500;
  width: 5em;
  height: 2.5em;
  border-radius: 5dvb;
  border: none;
  background-color: #ffffff;
}

.join-button {
  color: #12aeb4;
  font-weight: 500;
  width: 8em;
  height: 2.7em;
  border-radius: 5px;
  border: none;
  background-color: #ffffff;
}

.explore-button {
  color: #12aeb4;
  font-weight: 500;
  width: 6.5em;
  height: 2.7em;
  border-radius: 5px;
  border: none;
  background-color: #ffffff;
}

.notification-section {
  background-color: #f1f1f1;
  text-align: center;
  padding: 1rem;
  font-size: 1.2rem;
  color: #333;
}

.notification-section .update-link {
  color: #12aeb4;
  text-decoration: underline;
}

.notification-section .update-link:hover {
  color: #0a7d7e;
}
.custom-navbar .navbar-brand-custom {
  margin-right: auto;
}

.custom-navbar .right-nav .nav-item-custom,
.custom-navbar .search-form {
  margin-left: auto;
  margin-right: 10px; 
}

.login-button, .join-button {
  padding: 5px 10px;
  margin: 0 5px; 
}

.slide-card-course {
  width: 100%;
  max-width: 245px; 
  background: #ffffff;
  box-shadow: 5px 5px 20px rgba(60, 118, 119, 0.278);
  border: 1px solid #ebebeb;
  border-radius: 13px;
  padding: 10px;
  height: auto !important;
}

@media (min-width: 576px) {
  .slide-card-course{
    width: calc(50% - 2%);
  }
}

@media (min-width: 768px) {
  .slide-card-course {
    width: calc(33.33% - 2%);
  }
}

@media (min-width: 992px) {
  .slide-card-course {
    width: calc(25% - 2%); 
  }
}



.slide-card-social {
  width: 100%;
  max-width: 245px; 
  background: #ffffff;
  box-shadow: 5px 5px 20px rgba(60, 118, 119, 0.278);
  border: 1px solid #ebebeb;
  border-radius: 13px;
  padding: 10px;
  height: auto !important;
}

@media (min-width: 576px) {
  .slide-card-social{
    width: calc(50% - 2%);
  }
}

@media (min-width: 768px) {
  .slide-card-social {
    width: calc(33.33% - 2%);
  }
}

@media (min-width: 992px) {
  .slide-card-social {
    width: calc(25% - 2%); 
  }
}

.slide-card-event {
  background: #ffffff;
  box-shadow: 5px 5px 20px rgba(60, 118, 119, 0.2784313725);
  border: 1px solid #ebebeb;
  border-radius: 13px;
  padding: 10px;
  height: 9em !important;
}
