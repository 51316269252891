.gauge .plan {
  background: #ebf7f6;
  border-radius: 4px;
  margin-right: 38px;
  margin-bottom: 47px;
  width: 524px;
}

.gauge .plan-detail {
  font-size: larger;
  font-weight: bold;
}